// /home/ssm-user/microservices/ContentManagement/frontend/react/our-app/src/App.js
import React from 'react';
import Player from './components/Player';
import './App.css';

function App() {
    return (
        <div id="root" className="app-container">
            <Player />
        </div>
    );
}

export default App;

