import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'; // Ensures audio streaming is managed properly
import axios from 'axios';
import './Player.css';

const Player = () => {
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8); // Default volume level
    const [metadata, setMetadata] = useState({
        title: 'Stream', // Default title before metadata loads
        artist: 'Shaqfu Radio', // Default artist before metadata loads
        cover: 'https://shaqfuradio.s3.amazonaws.com/Other+Images/Shaq-Fu-Radio-Logo-White-80.png', // Default cover art
        duration: 0,
        currentTime: 0
    });
    const streamUrl = 'https://radiocustoms.cdnstream1.com/shaqfu'; // URL for streaming
    const metadataUrl = 'https://development.shaqfuradio.com/metadata'; // Endpoint for fetching metadata

    const togglePlay = () => {
        setPlaying(!playing); // Toggle play/pause state
    };

    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                const { data } = await axios.get(metadataUrl);
                setMetadata(metadata => ({ ...metadata, ...data })); // Update metadata state with fetched data
            } catch (error) {
                console.error('Error fetching metadata:', error); // Log errors if fetch fails
            }
        };

        fetchMetadata();
        const interval = setInterval(fetchMetadata, 10000); // Fetch metadata every 10 seconds
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
        <div className="player-container">
            <div className="album-art">
                <img src={metadata.cover} alt="Album Art" style={{ borderRadius: '5%', width: '65px', height: '65px' }} />
            </div>
            <div className="metadata">
                <h1 className="title">{metadata.title}</h1>
                <h2 className="artist">{metadata.artist}</h2>
            </div>
            <ReactPlayer
                className="react-player"
                url={streamUrl}
                playing={playing}
                volume={volume}
                controls={false}
                width="0"
                height="0"
            />
            <div className="controls">
                <button onClick={togglePlay} className={`play-button ${playing ? 'playing' : ''}`}>
                    {playing ? (
                        <svg viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M6 6h14v12H6z"/>
                        </svg>
                    ) : (
                        <svg viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M8 5v14l11-7z"/>
                        </svg>
                    )}
                </button>
                <div className="track-progress">
                    <input type="range" min="0" max={metadata.duration} value={metadata.currentTime}
                        onChange={(e) => setMetadata({...metadata, currentTime: parseFloat(e.target.value)})}
                        className="progress-bar" />
                    <span className="current-time">{metadata.currentTime}</span>
                    <span className="remaining-time">{metadata.duration - metadata.currentTime}</span>
                </div>
                <div className="volume-control">
                    <span className="volume-icon">🔊</span>
                    <input type="range" min="0" max="1" step="0.01" value={volume}
                        onChange={(e) => setVolume(parseFloat(e.target.value))} />
                </div>
            </div>
        </div>
    );
};

export default Player;

