// Import React and other necessary libraries
import React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import App from './App';
import './index.css';

// Pushgateway endpoint (replace with your actual server)
const PUSHGATEWAY_URL = "http://172.31.19.188:9091/metrics/job/frontend";

// Function to push metrics to Pushgateway
async function pushMetric(metricName, value, labels = {}) {
  const labelsString = Object.entries(labels)
    .map(([key, val]) => `${key}="${val}"`)
    .join(",");
  const payload = labelsString
    ? `${metricName}{${labelsString}} ${value}\n`
    : `${metricName} ${value}\n`;

  try {
    await axios.post(PUSHGATEWAY_URL, payload, {
      headers: { "Content-Type": "text/plain" },
    });
    console.log(`Metric ${metricName} pushed successfully.`);
  } catch (error) {
    console.error(`Error pushing metric: ${error.message}`); //THIS IS LINE 27 from the error we are trying to resolve
  }
}

// Example usage: Push page load metric
pushMetric("frontend_page_load", 1, { page: window.location.pathname });

// Setup React rendering
const root = createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

